// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import { Helmet } from 'react-helmet';
import { Modal } from 'components/core/modal';
import { Type } from '@seegno-labs/react-components/typography';
import { colors } from 'styles/colors';
import { map, noop } from 'lodash';
import { units } from '@seegno-labs/react-components/styles';
import Button from 'slyk-design-system/button';
import Form from 'components/core/forms/form';
import Input from 'components/core/forms/fields/input';
import Link from 'components/core/links/link';
import Loader from 'components/core/loader';
import Message from 'components/core/message';
import React, { Fragment, type Node, useState } from 'react';
import SubmitButton from 'components/core/forms/submit-button';
import styled from 'styled-components';

/**
 * Font components.
 */

const fontComponents = [{
  component: Type.H1,
  name: 'H1'
}, {
  component: Type.H2,
  name: 'H2'
}, {
  component: Type.H3,
  name: 'H3'
}, {
  component: Type.H4,
  name: 'H4'
}, {
  component: Type.H5,
  name: 'H5'
}, {
  component: Type.H6,
  name: 'h6'
}, {
  component: Type.Label,
  name: 'Label'
}, {
  component: Type.Paragraph,
  name: 'Paragraph'
}, {
  component: Type.Small,
  name: 'Small'
}];

/**
 * Json schema.
 */

const jsonSchema = {
  properties: {
    input: {
      minLength: 1,
      type: 'string'
    },
    select: {
      minLength: 1,
      type: 'string'
    },
    textarea: {
      format: 'email',
      type: 'string'
    }
  },
  required: ['input', 'select', 'textarea']
};

/**
 * `StyledSection` styled component.
 */

const StyledSection = styled.section`
  margin-bottom: ${units(6)};
`;

/**
 * `ColorContainer` styled component.
 */

const ColorContainer = styled.div`
  align-items: flex-end;
  background-color: ${({ color }) => color};
  border: 1px solid black;
  display: inline-flex;
  height: ${units(18)};
  justify-content: center;
  margin-bottom: ${units(2)};
  margin-right: ${units(2)};
  text-align: center;
  width: ${units(15)};
`;

/**
 * `ColorContainerInfo` styled component.
 */

const ColorContainerInfo = styled.div`
  background: ${colors.white};
  border-top: 1px solid black;
  padding: ${units(1)};
  width: 100%;
`;

/**
 * `Styleguide` container.
 */

const Styleguide = (): Node => {
  const [message, setMessage] = useState(false);
  const [isModalOpen, setModalOpen] = useState(true);

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>
            {'Styleguide'}
          </title>
        </Helmet>

        <Type.H2
          fontWeight={700}
          marginBottom={units(4)}
          marginTop={units(5)}
        >
          {'Styleguide'}
        </Type.H2>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Buttons'}
          </Type.H4>

          <Box
            display={'flex'}
            flexWrap={'wrap'}
          >
            <Button href={'http://www.seegno.com'}>
              {'Link'}
            </Button>

            <Box
              display={'inline-block'}
              width={'50px'}
            />

            <Button>
              {'Button primary'}
            </Button>

            <Box
              display={'inline-block'}
              width={'50px'}
            />

            <Button colorTheme={'secondary'}>
              {'Button secondary'}
            </Button>

            <Box
              display={'inline-block'}
              width={'50px'}
            />

            <Button colorTheme={'green'}>
              {'Button green'}
            </Button>

            <Box
              display={'inline-block'}
              width={'50px'}
            />

            <Button colorTheme={'grey'}>
              {'Button grey'}
            </Button>
          </Box>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Links'}
          </Type.H4>

          <Box
            display={'flex'}
            flexWrap={'wrap'}
          >
            <Link
              colorTheme={'grey'}
              href={'http://www.seegno.com'}
            >
              {'Link'}
            </Link>
          </Box>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Typography'}
          </Type.H4>

          <Box marginBottom={units(4)}>
            {fontComponents.map(({ component: Component, name }) => (
              <Component key={name}>
                {`${name}: Lorem ipsum is placeholder text`}
              </Component>
            ))}
          </Box>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Colors'}
          </Type.H4>

          <div>
            {map(colors, (color, colorName) => (
              <ColorContainer
                color={color}
                key={colorName}
              >
                <ColorContainerInfo>
                  <Type.Paragraph>
                    {colorName}
                  </Type.Paragraph>

                  <Type.Small>
                    {color}
                  </Type.Small>
                </ColorContainerInfo>
              </ColorContainer>
            ))}
          </div>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Modals'}
          </Type.H4>

          <Button onClick={() => setModalOpen(true)}>
            {'Open Modal'}
          </Button>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Form'}
          </Type.H4>

          <Box
            backgroundColor={colors.grey200}
            padding={units(4)}
          >
            <Form
              jsonSchema={jsonSchema}
              onSubmit={noop}
            >
              <Input
                label={'Input'}
                name={'input'}
                required
              />

              <Input
                as={'textarea'}
                label={'Textarea'}
                name={'textarea'}
                required
                rows={5}
              />

              <SubmitButton fullWidth>
                {'Submit'}
              </SubmitButton>
            </Form>
          </Box>
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Messages'}
          </Type.H4>

          <Box marginBottom={units(2)}>
            <Button onClick={() => setMessage(message === 'success' ? null : 'success')}>
              {`${message === 'success' ? 'Hide' : 'Show'} Message Success Template`}
            </Button>
          </Box>

          <div>
            <Button onClick={() => setMessage(message === 'error' ? null : 'error')}>
              {`${message === 'error' ? 'Hide' : 'Show'} Message Error Template`}
            </Button>
          </div>

          {message && (
            <Message
              onClose={() => setMessage(null)}
              type={message}
            >
              {`This is an example of a ${message} message`}
            </Message>
          )}
        </StyledSection>

        <StyledSection>
          <Type.H4
            fontWeight={700}
            marginBottom={units(2)}
          >
            {'Loader'}
          </Type.H4>

          <Loader />
        </StyledSection>
      </Container>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box minHeight={'100vh'}>
          {'Modal'}
        </Box>
      </Modal>
    </Fragment>
  );
};

/**
 * Export `Styleguide` container.
 */

export default Styleguide;
